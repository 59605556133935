import React, { Component } from "react";
import axios from "axios";
import "../css/formcompo.css";
import list from "../cssMod/listcompo.module.css";
import WorkInProgress from "./work_in_progress";
import { Modal, Button } from "react-bootstrap";
import PreferenceComponent from "./preference_component";
import "../css/relationlist.css";
import NewFilterCompo from "./NewFilterCompo";
import WithNavigate from "./withNavigate";
import Form_component from "./form_component";
import GlobalContext from "./GlobalContext ";
import FormInnerComponent from "./FormInner_Component";
import ModelList from "./model_list";

import {
  formChangefnunction,
  handleUpdateSaveFn,
  handleUpdateCancelFn,
  validationFnunction,
  handleRowClickFn,
  handleMouseDownFn,
  setcolumnFn,
  handleDoubleClickOpenForUpdateFn,
  handleMouseUpFn,
} from "../Utility/MultiUpdateService.js";

class RelationListComponent extends Component {
  static contextType = GlobalContext;

  state = {
    name: "",
    list: [],
    refList: [],
    button: [],
    mainrecord: {},
    page_clicked: 0,
    page_count: 0,
    page_records: "",
    record_count: 0,
    showlist: false,
    showbtn: false,
    col_mn: [],
    column_depend: [],
    showupdownbtn: false,
    filtarray: [],

    filt: {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "", label: "" },
      ch: [],
    },
    mainFilt: {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "", label: "" },
      ch: [],
    },
    filString: "",
    sortColumn: { name: "id", sort: false },
    listName: this.props.listName,
    filter: this.props.filter,
    timeline: this.props.timeline,
    loading: false,
    filter_unset: false,
    show: false,
    prefCall: [],
    recordList: {},
    tablabel: "",
    page_error: "",
    error: "",
    i_d: this.props.i_d,
    noRecord: false,
    loca: this.props.loca,
    columnList: [],
    srch_column: "name",
    srch: "",
    isMobile: this.props.isMobile,
    listRcdInfo: { rcdId: "0", tabName: "" },
    isListRcdInfo: false,
    contextMenu: this.props.contextMenu,
    showContext: false,
    relation_context: [],
    multiRowSelect: new Map(),
    cur_ref_name: "",
    cur_ref_type: "",
    cur_col_index: 0,
    cur_row_index: 0,
    cur_ref_obj: {},
    showmodel: false,
    tabId: "",
    tabName: "",
    notAllowedForUpdate: [
      "filter",
      "filter_ref",
      "other_table_filter",
      "filter_script",
      "json",
      "JSONObject",
      "group_key_value",
      "html",
      "script",
      "multi_select",
    ],
    record_rq: {},
    ref_filter: [],
    openInNewTab: false,
    newTabRcdId: "0",
    isDragging: false,
    dragStartCell: null,
  };

  constructor(props) {
    super(props);
    this.alreadyRun = React.createRef();
    this.menuX = React.createRef();
    this.menuY = React.createRef();
    this.state.recordList = props.recordList;
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.filterItem = this.filterItem.bind(this);
    this.setList = this.setList.bind(this);
    this.filtersubmit = this.filtersubmit.bind(this);
    this.filterClear = this.filterClear.bind(this);
    this.showupdownbtn = this.showupdownbtn.bind(this);
    this.setSort = this.setSort.bind(this);
    this.callform = this.callform.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.listRecord = this.listRecord.bind(this);
    this.searchColumn = this.searchColumn.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.callSearchbtn = this.callSearchbtn.bind(this);
    this.callfilter = this.callfilter.bind(this);
    this.calltimeline = this.calltimeline.bind(this);
    this.callbtn = this.callbtn.bind(this);
    this.handleCloseRefresh = this.handleCloseRefresh.bind(this);
    this.rightReadOnly = this.rightReadOnly.bind(this);
    this.leftReadOnly = this.leftReadOnly.bind(this);
    this.getChoiceRcd = this.getChoiceRcd.bind(this);
    this.listOfTypes = [
      "filter",
      "filter_ref",
      "json",
      "group_key_value",
      "html",
      "reference",
      "multi_select",
    ];

    this.state.filtarray.push(this.state.filt);
    this.rowsScriptHasBeenRun = React.createRef([]);
    this.multipleSelectedRows = React.createRef([]);
    this.callAginfm = React.createRef(false);
    this.changedRowAndCols = React.createRef(new Map());
  }

  componentDidMount() {
    const rel_context = this.state.contextMenu.filter(
      (item) => item.name === "refresh" || item.name === "openInNewTab"
    );
    this.setState({ relation_context: rel_context });

    document
      .getElementsByTagName("body")
      .item(0)
      .addEventListener("mousedown", () => {
        this.setState({ showContext: false });
      });
    this.listRecord(this.state.recordList);
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.listName !== this.state.listName &&
      nextProps.recordList !== this.state.recordList
    ) {
      this.listRecord(this.state.recordList);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.listName !== state.listName &&
      props.recordList !== state.recordList
    ) {
      return {
        listName: props.listName,
        filter: props.filter,
        recordList: props.recordList,
        column_depend: [],
      };
    } else if (props.listName === state.listName) {
      if (props.filter !== state.filter) {
        return {
          filter: props.filter,
          column_depend: [],
        };
      }
    }
    if (state.contextMenu === props.contextMenu) {
      return {
        contextMenu: props.contextMenu,
      };
    } else if (state.contextMenu !== props.contextMenu) {
      return {
        contextMenu: props.contextMenu,
      };
    }
    return null;
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleCloseRefresh() {
    this.setState({ show: false });
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","value":"loom"}}';
    tablere +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"false","column":"id"}}';
    tablere += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    tablere += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.setList(tablere);
  }

  leftReadOnly() {
    if (this.state.page_clicked === 1) {
      return true;
    } else {
      return false;
    }
  }

  rightReadOnly() {
    if (this.state.page_clicked === this.state.page_count) {
      return true;
    } else {
      return false;
    }
  }

  previousPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.page_clicked - 1) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';

    this.setList(pp);
  }

  nextPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.page_clicked + 1) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';

    this.setList(pp);
  }

  checkIfTypeNotExists = (type) => {
    for (let i = 0; i < this.listOfTypes.length; i++) {
      if (this.listOfTypes[i] === type) {
        return false;
      }
    }
    return true;
  };

  openModalList() {
    this.setState({ showmodel: true });
  }
  handleModalList = () => {
    this.setState({ showmodel: false });
  };

  firstPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';

    this.setList(pp);
  }

  lastPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"3","value":"' +
      this.props.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.record_count % 50 === 0
        ? this.state.page_count - 1
        : this.state.page_count) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.setList(pp);
  }

  showupdownbtn(hd) {
    var sarray = this.state.sortColumn;
    let flt = JSON.parse(JSON.stringify(this.state.filtarray));

    var clmn = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < this.state.list.length; l++) {
        for (var ll = 0; ll < this.state.list[l].heading.length; ll++) {
          if (this.state.list[l].heading[ll].name === hd) {
            clmn = this.state.list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < this.state.list.length; li++) {
        for (var lll = 0; lll < this.state.list[li].heading.length; lll++) {
          if (this.state.list[li].heading[lll].name === hd) {
            clmn = this.state.list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    }

    let len = this.state.filtarray.length;
    if (this.state.filtarray[len - 1].co !== "") {
      if (
        this.state.filtarray[len - 1].an !== "" ||
        this.state.filtarray[len - 1].rf.value !== "" ||
        this.state.filtarray[len - 1].dc.value !== ""
      ) {
        this.setState({ sortColumn: sarray });
        pp += ',{"filter":' + JSON.stringify(flt) + "}";
        pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
        this.setList(pp);
      } else {
        this.state.filtarray.pop();
        this.setState({ filtarray: this.state.filtarray });
        this.setState({ sortColumn: sarray });
        pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
        pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
        this.setList(pp);
      }
    } else {
      this.setState({ sortColumn: sarray });
      pp += ',{"filter":' + JSON.stringify(flt) + "}";
      pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
      this.setList(pp);
    }
  }

  fieldverify = (type, vl) => {
    // this.setState({ page_error: false, error: "", page_message: false, message: "" });
    if (type === "String") {
      return "verified";
    }
    if (type === "email") {
      if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "int") {
      if (/^[0-9]*$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "date") {
      if (/^[+-]?\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "time") {
      if (/(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "reference") {
      if (/[a-zA-Z]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "filter") {
      if (/[a-zA-Z]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }

    if (type === "aadhar_number") {
      if (/\d{12}/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
  };

  setSort() {
    var clmn = "";
    var pp = "";
    var srt = this.state.sortColumn.name;
    if (srt !== "") {
      for (var l = 0; l < this.state.list.length; l++) {
        for (var ll = 0; ll < this.state.list[l].heading.length; ll++) {
          if (
            this.state.list[l].heading[ll].name === this.state.sortColumn.name
          ) {
            clmn = this.state.list[l].heading[ll].name;
            break;
          }
        }
      }
      if (this.state.sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '"}}';
      } else {
        pp += ',{"sort":{"asc":"false","column":"' + clmn + '"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"false","column":"' +
        this.state.list[0].heading[0].name +
        '"}}';
    }
    return pp;
  }

  setList(tablere) {
    var token = localStorage.getItem("token");
    this.setState({ loading: true });
    axios
      .post(this.state.loca + "/loom/get/relation/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const listrecord = resp.data;
          if (listrecord !== "") {
            if ("Error" in listrecord) {
              this.setState({
                loading: false,
                page_error: true,
                error: listrecord.Error,
              });
            } else {
              this.listRecord(listrecord);
            }
          }
        },
        (error) => {
          this.props.showErrorCompo();
        }
      );
  }

  getFieldValue = (col_id, obj) => {
    //   var rcd = this.state.record;
    let otb = this.state.list;
    var rcd = otb[0].rcd[obj.rowIndex].colr;
    if (rcd !== "null" && rcd !== "") {
      for (var r = 0; r < rcd.length; r++) {
        if (rcd[r].id === col_id) {
          return rcd[r].value;
        }
      }
    }
  };

  listRecord(listrecord) {
    console.log("relation", this.state.loading);
    console.log("relation  55", listrecord);
    this.setState({ loading: true });
    var columnarry = [];
    var hd = [];
    var va_ll = [];
    var col_list = [];
    if (listrecord !== "") {
      if ("Error" in listrecord) {
        this.setState({
          loading: false,
          page_error: true,
          error: listrecord.Error,
        });
      } else {
        var scnd = listrecord.formRecordList[2];
        let tbName = listrecord.formRecordList[1].table.value;

        if ("Error" in scnd) {
          if (scnd.Error === "No record found.") {
            this.setState({ list: [] });
          }
        } else {
          var page_clicked1 = parseInt(
            listrecord.formRecordList[3].page.page_clicked
          );
          var page_count1 = parseInt(
            listrecord.formRecordList[3].page.page_count
          );
          var page_records1 = listrecord.formRecordList[3].page.page_records;
          var record_count1 = parseInt(
            listrecord.formRecordList[3].page.record_count
          );
          var flt = listrecord.formRecordList[5];
          var fltarr = listrecord.formRecordList[5].filter;
          var tmln = listrecord.formRecordList[6].timeLine;
          var filterString = "";
          var leng = fltarr.length;
          for (var f = 0; f < fltarr.length; f++) {
            if (leng === 1 && fltarr[f].cl === "") {
              break;
            } else {
              if (f > 0) {
                filterString += fltarr[f].af + " ";
              }
              filterString += fltarr[f].cl + " ";
              filterString += fltarr[f].mc + " ";
              if (fltarr[f].ct === "reference") {
                filterString += fltarr[f].rf.value + " ";
              } else {
                filterString += fltarr[f].an + " ";
              }
            }
          }
          if (listrecord.formRecordList[2].records.length === 0) {
            this.setState({ noRecord: true });
          } else {
            this.setState({ noRecord: false });
          }
          for (
            var i = 0;
            i < listrecord.formRecordList[2].records.length;
            i++
          ) {
            var in_vl = [];
            var record_id;
            var ref_value;
            for (
              var j = 0;
              j < listrecord.formRecordList[2].records[i].record.length;
              j++
            ) {
              if (j === 0) {
                record_id =
                  listrecord.formRecordList[2].records[i].record[j].value;
                // if (
                //   listrecord.formRecordList[2].records[i].record[j].refDisplay
                //     .type === "reference"
                // ) {
                //   ref_value =
                //     listrecord.formRecordList[2].records[i].record[j].refDisplay
                //       .value.value;
                // } else {
                ref_value =
                  listrecord.formRecordList[2].records[i].record[j].refDisplay
                    .value;
                // }
              } else if (j === 1) {
                // if (
                //   listrecord.formRecordList[2].records[i].record[j].type ===
                //   "reference"
                // ) {
                //   in_vl.push({
                //     value:
                //       listrecord.formRecordList[2].records[i].record[j].value
                //         .value,
                //     type: listrecord.formRecordList[2].records[i].record[j]
                //       .type,
                //     firstrecord: true,
                //     table: listrecord.formRecordList[1].table.value,
                //     r_id: record_id,
                //     ref_value: ref_value,
                //     isInPref:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .isInPref,
                //   });
                // } else {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: true,
                  table: listrecord.formRecordList[1].table.value,
                  r_id: record_id,
                  columnAccess:
                    listrecord.formRecordList[2].records[i].record[j]
                      .columnAccess,
                  displayColumn:
                    listrecord.formRecordList[2].records[i].record[j]
                      .displayColumn,
                  uivalid:
                    listrecord.formRecordList[2].records[i].record[j].uivalid,
                  validation:
                    listrecord.formRecordList[2].records[i].record[j]
                      .validation,
                  name: listrecord.formRecordList[2].records[i].record[j].name,
                  id: listrecord.formRecordList[2].records[i].record[j].id,
                  rowIndex: i,
                  isInPref:
                    listrecord.formRecordList[2].records[i].record[j].isInPref,
                  choice:
                    listrecord.formRecordList[2].records[i].record[j].choice,
                  label:
                    listrecord.formRecordList[2].records[i].record[j].label,
                });
                //  }
              } else {
                // if (
                //   listrecord.formRecordList[2].records[i].record[j].type ===
                //   "reference"
                // ) {
                //   in_vl.push({
                //     value:
                //       listrecord.formRecordList[2].records[i].record[j].value
                //         .value,
                //     type: listrecord.formRecordList[2].records[i].record[j]
                //       .type,
                //     firstrecord: false,
                //     table: listrecord.formRecordList[1].table.value,
                //     r_id: record_id,
                //     isInPref:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .isInPref,
                //   });
                // } else {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: false,
                  table: listrecord.formRecordList[1].table.value,
                  r_id: record_id,
                  columnAccess:
                    listrecord.formRecordList[2].records[i].record[j]
                      .columnAccess,
                  displayColumn:
                    listrecord.formRecordList[2].records[i].record[j]
                      .displayColumn,
                  uivalid:
                    listrecord.formRecordList[2].records[i].record[j].uivalid,
                  validation:
                    listrecord.formRecordList[2].records[i].record[j]
                      .validation,
                  name: listrecord.formRecordList[2].records[i].record[j].name,
                  id: listrecord.formRecordList[2].records[i].record[j].id,
                  rowIndex: i,
                  isInPref:
                    listrecord.formRecordList[2].records[i].record[j].isInPref,
                  choice:
                    listrecord.formRecordList[2].records[i].record[j].choice,
                  label:
                    listrecord.formRecordList[2].records[i].record[j].label,
                });

                //   }
              }
            }
            // va_ll.push({ colr: in_vl });

            in_vl.splice(0, 0, { ref: false, isInPref: true });
            va_ll.push({ colr: in_vl });
          }
          for (var c = 0; c < listrecord.formRecordList[9].column.length; c++) {
            if (listrecord.formRecordList[9].column[c].type === "String") {
              col_list.push({
                label: listrecord.formRecordList[9].column[c].label,
                name: listrecord.formRecordList[9].column[c].name,
                type: listrecord.formRecordList[9].column[c].type,
              });
            }
          }
          for (
            var p = 0;
            p < listrecord.formRecordList[10].preference.length;
            p++
          ) {
            hd.push({
              label: listrecord.formRecordList[10].preference[p].label,
              name: listrecord.formRecordList[10].preference[p].name,
            });
          }
          hd.splice(0, 0, { ref: false });
          columnarry.push({ heading: hd, rcd: va_ll });
          var cla = JSON.parse(
            JSON.stringify(listrecord.formRecordList[9].column)
          );
          cla.unshift({ label: "None", name: "none" });
          let jj = JSON.parse(JSON.stringify(columnarry));
          this.setState({
            tablabel: listrecord.formRecordList[1].table.label,
            loading: false,
            list: columnarry,
            refList: jj,
            page_clicked: page_clicked1,
            page_count: page_count1,
            page_records: page_records1,
            record_count: record_count1,
            col_mn: cla,
            prefCall: listrecord.formRecordList[9].column,
            filter: flt,
            filtarray: fltarr,
            timeline: tmln,
            filString: filterString,
            button: listrecord.formRecordList[12].button,
            columnList: col_list,
            tabName: tbName,
          });
          this.checkRefrecord();
        }
      }
    }
  }

  async checkRefrecord() {
    var token = localStorage.getItem("token");
    axios
      .get(
        this.state.loca +
          "/loom/get/reference/qualifier/" +
          this.state.listName,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then(
        (resp) => {
          const rq_data = resp.data;
          if (rq_data !== "") {
            this.setState({ record_rq: rq_data });
            // if (this.uiscript) {
            //   let script = this.uiscript.current;
            // }
          }
        },
        (error) => {
          let err = {
            message: error.message,
            code: error.response.status,
          };
          this.props.showErrorCompo({ state: { err: err } });
        }
      );
  }

  callform(e, nam, tab, r_id) {
    const { navigateWithSearchParams } = this.props;
    if (nam === "first") {
      //this.props.showFormCompo(tab, r_id, "record");
      if (e.button === 0) {
        navigateWithSearchParams(
          "/private/form",
          {
            rty: "record",
            tableName: tab,
            rid: r_id,
          },
          {}
        );
      } else if (e.button === 1) {
        const newWindow = window.open(
          `/private/form?rty=record&tableName=${tab}&rid=${r_id}`,
          `_blank`
        );
        let lab = this.state.tablabel;
        newWindow.onload = () => {
          newWindow.document.title = lab + " Record";
        };
      }
    } else if (nam === "second") {
    } else if (nam === "new") {
      let rcd = this.props.record;

      let id = "0";
      let disPlayCol = "";
      let uni_id = "";
      for (let i = 0; i < rcd.length; i++) {
        if (rcd[i].name === "id") {
          id = rcd[i].value;
        } else if (rcd[i].displayColumn === "true") {
          disPlayCol = rcd[i].value;
        } else if (rcd[i].name === "uni_id") {
          uni_id = rcd[i].value;
        }
      }
      if (disPlayCol === "") disPlayCol = uni_id;
      let obj = { id: id, value: disPlayCol };
      navigateWithSearchParams(
        "/private/form",
        {
          rty: "new",
          tableName: tab,
          rid: 0,
        },
        { tabName: this.state.listName, value: JSON.stringify(obj), rcd: rcd }
      );
    }
  }

  dismiss() {
    this.props.unmountMe();
  }

  filterItem() {
    this.setState({ showlist: !this.state.showlist });
    this.setState({ showbtn: !this.state.showbtn });
  }

  filterClear() {
    if (this.state.filtarray.length > 1) {
      let filt = this.state.filtarray[0];
      let fltarray = [];
      fltarray.push(filt);
      this.setState({ filtarray: filt, noRecord: false });
      var tm = "";
      this.setState({ timeline: tm });
      var pp = '{"formRecordList":[';
      pp += '{"application":{"id":"","value":"loom"}}';
      pp +=
        ',{"table":{"id":"","value":"' +
        this.state.listName +
        '","label":"' +
        this.state.tablabel +
        '"}}';
      pp += ',{"records":[]}';
      pp +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"1","page_records":"0"}}';
      pp += this.setSort();
      pp += ',{"filter":' + JSON.stringify(fltarray) + "}";
      pp += ',{"timeLine":"' + tm + '"}]}';
      this.setList(pp);
    }
  }

  filtersubmit() {
    this.alreadyRun.current = true;
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","value":"loom"}}';
    fs +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"false","column":"id"}}';
    fs += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    fs += ',{"timeLine":"' + this.state.timeline + '"}]}';
    let len = this.state.filtarray.length;
    if (this.state.filtarray[len - 1].co !== "") {
      if (
        this.state.filtarray[len - 1].an !== "" ||
        this.state.filtarray[len - 1].rf.value !== "" ||
        this.state.filtarray[len - 1].dc.value !== ""
      ) {
        this.setList(fs);
      }
    } else {
      this.setList(fs);
    }
  }

  callbtn(nam) {
    var btn = this.state.button;
    var mnrecord = this.state.mainrecord;

    var btntype = "";
    var calltype = "";
    var foundbtn = false;

    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].name === nam) {
        btntype = btn[ij].returnWebLocation;
        calltype = btn[ij].call_type;
        foundbtn = true;
        break;
      }
    }
    if (foundbtn === true && btntype !== "" && calltype !== "") {
      this.setState({
        page_error: false,
        error: "",
        loading: true,
        page_message: false,
        message: "",
      });
      const { navigateWithSearchParams } = this.props;

      if (calltype === "module") {
        //  this.props.showFormCompo(this.state.listName, 0, "new");
        // this.callform("new", this.state.listName, 0);
        let rcd = this.props.record;

        let id = "0";
        let disPlayCol = "";
        let uni_id = "";
        for (let i = 0; i < rcd.length; i++) {
          if (rcd[i].name === "id") {
            id = rcd[i].value;
          } else if (rcd[i].displayColumn === "true") {
            disPlayCol = rcd[i].value;
          } else if (rcd[i].name === "uni_id") {
            uni_id = rcd[i].value;
          }
        }
        if (disPlayCol === "") disPlayCol = uni_id;
        let obj = { id: id, value: disPlayCol };
        navigateWithSearchParams(
          "/private/form",
          {
            rty: "new",
            tableName: this.state.listName,
            rid: 0,
          },
          { tabName: this.props.parentTabName, value: obj, rcd: rcd }
        );

        // navigateWithSearchParams('/private/form', {
        //   rty: "new",
        //   tableName: this.state.listName,
        //   rid:0
        // },{});
      } else if (calltype === "selection") {
        var returnType = btn[ij].recordReturnType;
        // this.props.showRoleSelectionCompo(this.state.i_d, returnType);
        /*    i_d: id,
       rt: rt, */
        navigateWithSearchParams(
          "/private/role-select",
          {
            i_d: this.state.i_d,
            rt: returnType,
          },
          {}
        );
      } else {
        mnrecord.formRecord[2].record = this.state.record;
        var token = localStorage.getItem("token");
        axios
          .post(this.state.loca + btn[ij].webUrl, mnrecord, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          })
          .then((resp) => {
            const rcd = resp.data;
            this.setState({ loading: false });
            var msg = rcd.formRecord[4].message;
            if (msg !== "") {
              this.setState({ page_message: true, message: msg });
            }
            if (btntype === "NextPage") {
              // var nextP = btn[ij].nextPage;
              var nextP = rcd.formRecord[3].button.nextPage;
              if (nextP === "List") {
                this.callNextPage();
              }
            }
          });
      }
    }
  }

  async callfilter(filtarray, in_index, col_id) {
    var rcd = this.state.list;
    if (col_id !== -1) {
      for (let i = 0; i < rcd.length; i++) {
        if (
          filtarray[in_index].ct === "choice" &&
          filtarray[in_index].ch.length === 0
        ) {
          await this.getChoiceRcd(col_id).then((res) => {
            if (res.length > 0) {
              filtarray[in_index].ch = res;
              filtarray[in_index].an = res[0].name;
            } else {
              filtarray[in_index].ch = [];
            }
          });
        }
      }
    }
    this.setState({
      filtarray: filtarray,
    });
  }

  getMultiSelectValues = (obj) => {
    console.log(obj);
    let rcd = obj.record;
    let res = "";
    if (rcd?.length != 0) {
      for (let k = 0; k < 10 && k < rcd?.length; k++) {
        if (k > 0) res += ",";
        res = res + rcd[k]?.value;
      }
    }
    console.log(res);
    return res;
  };

  async getChoiceRcd(col_id) {
    var token = localStorage.getItem("token");
    let ck = await axios
      .get(this.state.loca + "/loom/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          if ("Error" in res) {
            this.setState({
              loading: false,
              page_error: true,
              error: res.Error,
            });
          } else {
            let chk = res.choiceRecords;
            chk.unshift({ name: "none", value: "None" });
            return chk;
          }
        },
        (error) => {
          this.props.showErrorCompo();
        }
      );
    return ck;
  }

  calltimeline(timeline) {
    this.setState({
      timeline: timeline,
    });
  }

  searchColumn(e) {
    this.setState({ srch_column: e.target.value });
  }

  setContext = (val, x, y) => {
    console.log("err", val);

    if (val === 2) {
      this.menuX.current = x;
      this.menuY.current = y;
      this.setState({ showContext: true });
    } else {
      this.setState({ showContext: false });
    }
  };

  changeSearch(val) {
    if (val === "") {
      this.setState({ srch: "" });
      this.filterClear();
    } else {
      this.setState({ srch: val });
    }
  }

  callSearchbtn() {
    var fld = this.state.srch_column;
    var val = this.state.srch;
    var col = this.state.columnList;
    var type = "";
    var label = "";
    for (var c = 0; c < col.length; c++) {
      if (fld === col[c].name) {
        type = col[c].type;
        label = col[c].label;
      }
    }
    if (val.length > 2) {
      let filter = [
        {
          ct: type,
          af: "",
          mc: "contains",
          cl: label,
          co: fld,
          an: val,
          rf: { id: "", value: "" },
        },
      ];
      var fs = '{"formRecordList":[';
      fs += '{"application":{"id":"","value":"loom"}}';
      fs +=
        ',{"table":{"id":"","value":"' +
        this.state.listName +
        '","label":"' +
        this.state.tablabel +
        '"}}';
      fs += ',{"records":[]}';
      fs +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"1","page_records":"0"}}';
      fs += ',{"sort":{"asc":"false","column":"id"}}';
      fs += ',{"filter":' + JSON.stringify(filter) + "}";
      fs += ',{"timeLine":""}]}';
      this.setList(fs);
    }
  }

  selectedInfo = (index) => {
    let rcdId = this.state.list[0].rcd[index].colr[0].r_id;
    let tabName = this.state.listName;
    let rcdInfo = true;
    let obj = { rcdId: rcdId, tabName: tabName, rcdInfo: rcdInfo };
    this.setState({ listRcdInfo: obj, isListRcdInfo: true });
  };

  handleCloseListRcdInfo = () => {
    this.setState({
      isListRcdInfo: false,
      listRcdInfo: { rcdId: "0", tabName: "" },
    });
  };

  menuFn = (body) => {
    let lb = this.state.tablabel;
    if (body === "refresh()") {
      this.handleCloseRefresh();
    } else if (body === "newTab()") {
      const newWindow = window.open(
        `/private/list?rty=${"recordList"}&tableName=${
          this.state.listName
        }&filter=${JSON.stringify(this.state.filtarray)}`,
        `_blank`
      );
      newWindow.onload = () => {
        newWindow.document.title = lb + " " + "All";
      };
    }
  };

  isColSelected = (row, col) => {
    // let multiRowSelect = new Map(this.state.multiRowSelect);

    // if(multiRowSelect.get(row)){
    //    if(col==multiRowSelect.get(row))
    //     return true;
    // }
    // return false;
    if (!this.multipleSelectedRows.current) {
      this.multipleSelectedRows.current = [];
    }
    let newMultipleSelectedRows = this.multipleSelectedRows.current;
    //  console.log("isColSelected" ,newMultipleSelectedRows);
    // console.log("row" ,row ,col , this.state.multiSelectCol);
    if (
      col === this.state.multiSelectCol &&
      newMultipleSelectedRows?.includes(row)
    )
      return true;
    return false;
  };

  formChangefn = (
    vl /* value */,
    index /* index */,
    ob /* filed */,
    type,
    id,
    vrf,
    readonly,
    outerIndex,
    innerIndex,
    nme,
    obj,
    rcd_info
  ) => {
    formChangefnunction({
      thiss: this,
      vl /* value */,
      index /* index */,
      ob /* filed */,
      type,
      id,
      vrf,
      readonly,
      outerIndex,
      innerIndex,
      nme,
      obj,
      rcd_info,
    });
  };

  setRef = (val, ref_id) => {
    console.log("setRefcalled");

    this.setRefrecord(
      val,
      this.state.cur_col_index,
      this.state.cur_ref_name,
      this.state.cur_ref_type,
      ref_id
    );
    this.handleModalList();
  };

  setRefrecord = (vl, col_ind, ob, type, id) => {
    formChangefnunction({
      thiss: this,
      vl,
      index: col_ind,
      ob,
      type,
      id,
      vrf: true,
      readonly: undefined,
      outerIndex: undefined,
      innerIndex: "",
      nme: "",
      obj: this.state.cur_ref_obj,
      axios,
    });
  };

  handleUpdateSave = (rowIndex, colCell) => {
    handleUpdateSaveFn({ rowIndex, colCell, thiss: this, axios });
  };

  handleUpdateCancel = (rowIndex, colCell) => {
    handleUpdateCancelFn({ rowIndex, colCell, thiss: this });
  };

  handleRowClick = (e, rowIndex, colCel) => {
    handleRowClickFn({ e, rowIndex, colCel, thiss: this });
  };

  handleMouseDown = (rowIndex, colIndex, event) => {
    handleMouseDownFn({ rowIndex, colIndex, event, thiss: this, document });
  };

  handleMouseUp = () => {
    // this.setState({
    //   isDragging: false,
    //   dragStartCell: null,
    // });
    //      // Re-enable text selection
    // document.body.style.userSelect = "auto";

    // // Reset cursor back to default
    // document.body.style.cursor = "default";
    handleMouseUpFn({ thiss: this, document });
  };

  handleMouseOver = (rowIndex, colIndex) => {
    const { isDragging, dragStartCell } = this.state;

    if (isDragging && dragStartCell) {
      const selected = this.multipleSelectedRows.current;
      for (
        let i = Math.min(dragStartCell.rowIndex, rowIndex);
        i <= Math.max(dragStartCell.rowIndex, rowIndex);
        i++
      ) {
        if (!selected.includes?.(i)) selected.push(i);
      }
      // this.setState({ selectedCells: selected })
      this.multipleSelectedRows.current = selected;
    }
  };

  validationfn = (vl, index, ob, type, id, obj) => {
    validationFnunction({ vl, index, ob, type, id, obj, thiss: this, axios });
  };
  handleDoubleClickOpenForUpdate = (rowIndex, colCell) => {
    handleDoubleClickOpenForUpdateFn({ rowIndex, colCell, thiss: this });
  };

  setcolumn = (index, name, type, col, readonly, obj) => {
    setcolumnFn({ index, name, type, col, readonly, obj, thiss: this });
  };
  formInner = (objr, ot) => {
    return (
      <FormInnerComponent
        obj={objr}
        m_rid={ot?.recordId}
        index={ot.colIndex}
        rowIndex={ot?.rowIndex}
        multiUpdate={true}
        //   key={ objr_i}
        //   record={this.state.record}
        //   tabname={this.tablename.current}
        validationfn={this.validationfn}
        formChangefn={this.formChangefn}
        //   setRefrecord={this.setRefrecord}
        setcolumn={this.setcolumn}
        //   calltimeline={this.calltimeline}
        //   callfilter={this.callfilter}
        //   callScript={this.callScript}
        //   deleteImage={this.deleteImage}
        //   handleFileChange={this.handleFileChange}
        //   launchChoose={this.launchChoose}
        //   setMSC={this.setMSC}
        //   refrecord={this.state.refrecord}
        //   choice_mn={this.state.choice_mn}
        //   showlist={this.state.showlist}
        //   col_mn={this.state.col_mn}
        //   col_depend={this.state.column_depend}
        //   filtarray={this.state.filtarray}
        //   timeline={this.state.timeline}
        //   loca={this.state.loca}
        //   tabId={this.state.tabId}
        //   isMobile={this.state.isMobile}
        //   column_other={this.state.column_other}
        //   reScript={this.state.reScript}
        //   editor={this.state.editor}
        //   mscList={this.state.mscList}
        //   setContextMenu={this.setContextMenu}
        //   getSingleInfo={this.getSingleInfo}
        //   validation={this.state.validation}
        //   validation_error={this.state.validation_error}
        //   verify_error={this.state.verify_error}
        //   ob={this.state.ob}
        //   setref_filter={this.state.filtRefArray}
        //   col_mn_ref={this.state.column_ref}
        //   keyValueJson={this.state.keyValueJson}
        //   groupkeyValue={this.state.groupkeyValue}
        //   isRecent={this.state.isRecent}
        //   showRecent={this.showRecent}
      ></FormInnerComponent>
    );
  };

  render() {
    return (
      <div className="pagesetup">
        {this.state.loading === true ? (
          <div className="g-compo-loader">
            <WorkInProgress></WorkInProgress>
          </div>
        ) : (
          <div>
            {this.state.isMobile ? (
              <div className="row bck bck-rel">
                <div className="col-lg mid">
                  <span className="obj_head ">{this.state.tablabel}</span>
                </div>
                <div className="col-lg filter-ic">
                  {this.state.showlist === false && (
                    <select
                      className="form-select namelist-mob"
                      aria-label="Default"
                      value={this.state.srch_column}
                      onChange={(e) => this.searchColumn(e)}
                    >
                      {this.state.columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}
                  {this.state.showlist === false && (
                    <input
                      className="srch-mob form-control"
                      type="search"
                      aria-label="Search"
                      value={this.state.srch}
                      aria-hidden="true"
                      onChange={(e) => this.changeSearch(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.callSearchbtn();
                        }
                      }}
                    ></input>
                  )}
                  {this.state.showlist === false && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Search"
                      aria-hidden="true"
                      onClick={() => this.callSearchbtn()}
                    ></input>
                  )}
                </div>
                <div className="col-lg martop10 disfl filter-ic">
                  <i
                    className="fa fa-filter vlpointer pdtop5"
                    aria-hidden="true"
                    onClick={this.filterItem}
                  ></i>
                  <i
                    className="fa fa-cog vlpointer cog_pd pdtop5"
                    aria-hidden="true"
                    onClick={this.handleShow}
                  ></i>
                  <div className="">
                    <span style={{ marginLeft: "0.5em" }}>
                      <input
                        className=" mybt"
                        type="button"
                        value="<<"
                        onClick={this.firstPage}
                        disabled={this.leftReadOnly()}
                      />
                      <input
                        className=" mybt"
                        type="button"
                        value="<"
                        onClick={this.previousPage}
                        disabled={this.leftReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className="in-put"
                        type="text"
                        value={this.state.page_records}
                        readOnly={true}
                      ></input>
                    </span>
                    <span className="text-uppercase spfont"> of </span>
                    <span className="rcd_count spfont">
                      {this.state.record_count}
                    </span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">"
                        onClick={this.nextPage}
                        disabled={this.rightReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">>"
                        disabled={this.rightReadOnly()}
                        onClick={this.lastPage}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg mt-2">
                  <div className="row">
                    <div className="col-8">
                      {this.state.showlist === true && (
                        <input
                          className="csm_btn csm_btn_pri col-md-2 sub-btn"
                          type="button"
                          value="Run"
                          // aria-hidden="true"
                          onClick={this.filtersubmit}
                        ></input>
                      )}
                      {this.state.showlist === true && (
                        <input
                          className="csm_btn csm_btn_pri col-md-2 sub-btn"
                          type="button"
                          value="Clear"
                          // aria-hidden="true"
                          onClick={this.filterClear}
                        ></input>
                      )}
                    </div>
                    <div className="col-4">
                      {this.state.button.map(
                        (obj, oo_i) =>
                          obj.ui_type === "formButton" && (
                            <input
                              type="button"
                              className="csm_btn csm_btn_pri col-md-2 sub-btn"
                              key={oo_i}
                              onClick={(e) => this.callbtn(obj.name)}
                              value={obj.value}
                            ></input>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="row bck bck-rel"
                onContextMenu={(e) => {
                  e.preventDefault();
                  this.setContext(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {/* <div
                   className={`${list.bck}`}
                   style={{ padding:"3px"}}  
                   className="row" 
                  onContextMenu={(e) => {
                    e.preventDefault();
                    this.setContext(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }}
                >  */}
                {this.state.showContext && (
                  <div>
                    <ul
                      className="dropdown-menu"
                      style={{
                        display: "block",
                        top: this.menuY.current,
                        left: this.menuX.current,
                        padding: "0px",
                        fontSize: "14px",
                        borderRadius: "0px",
                      }}
                    >
                      {this.state.relation_context &&
                        this.state.relation_context.map((obj, ind) => (
                          <div key={ind}>
                            <li
                              onMouseDown={() => {
                                this.menuFn(obj.script);
                              }}
                            >
                              <a
                                className="dropdown-item"
                                style={{
                                  borderBottom:
                                    ind !== this.state.contextMenu.length - 1 &&
                                    "1px solid #e3e3e3",
                                  fontWeight: "500",
                                }}
                                href="#"
                              >
                                {obj.Label}
                              </a>
                            </li>
                          </div>
                        ))}
                    </ul>
                  </div>
                )}

                <div className="col-md-5 filter-ic">
                  <i
                    className="fa fa-filter vlpointer"
                    aria-hidden="true"
                    onClick={this.filterItem}
                  ></i>
                  <i
                    className="fa fa-cog vlpointer cog_pd"
                    aria-hidden="true"
                    onClick={this.handleShow}
                  ></i>
                  {this.state.showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Run"
                      // aria-hidden="true"
                      onClick={this.filtersubmit}
                    ></input>
                  )}

                  {this.state.showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Clear"
                      // aria-hidden="true"
                      onClick={this.filterClear}
                    ></input>
                  )}
                  {this.state.showlist === false && (
                    <select
                      className="form-select namelist"
                      aria-label="Default"
                      value={this.state.srch_column}
                      onChange={(e) => this.searchColumn(e)}
                    >
                      {this.state.columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}
                  {this.state.showlist === false && (
                    <input
                      className="srch form-control"
                      type="search"
                      aria-label="Search"
                      value={this.state.srch}
                      aria-hidden="true"
                      onChange={(e) => this.changeSearch(e.target.value)}
                    ></input>
                  )}
                  {this.state.showlist === false && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Search"
                      aria-hidden="true"
                      onClick={() => this.callSearchbtn()}
                    ></input>
                  )}
                </div>
                <div className="col-md-2 mid">
                  <span className="obj_head ">{this.state.tablabel}</span>
                </div>
                <div className="col-md-5 ">
                  <div className="tpi">
                    {this.state.button.map((obj, oo_i) => (
                      <input
                        type="button"
                        className="csm_btn csm_btn_pri col-md-2 sub-btn"
                        key={oo_i}
                        onClick={(e) => this.callbtn(obj.name)}
                        value={obj.value}
                      ></input>
                    ))}
                    <span style={{ marginLeft: "0.5em" }}>
                      <input
                        className=" mybt"
                        type="button"
                        value="<<"
                        onClick={this.firstPage}
                        disabled={this.leftReadOnly()}
                      />
                      <input
                        className=" mybt"
                        type="button"
                        value="<"
                        onClick={this.previousPage}
                        disabled={this.leftReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className="in-put"
                        type="text"
                        value={this.state.page_records}
                        readOnly={true}
                      ></input>
                    </span>
                    <span className="text-uppercase spfont"> of </span>
                    <span className="rcd_count spfont">
                      {this.state.record_count}
                    </span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">"
                        onClick={this.nextPage}
                        disabled={this.rightReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">>"
                        disabled={this.rightReadOnly()}
                        onClick={this.lastPage}
                      />
                    </span>
                  </div>
                </div>

                {/* </div> */}
              </div>
            )}
            {this.state.showlist === false && this.state.filString !== "" && (
              <div className="clr">{this.state.filString}</div>
            )}
            {this.state.showlist === true && (
              <NewFilterCompo
                showlist={this.state.showlist}
                col_mn={this.state.col_mn}
                col_depend={this.state.column_depend}
                call_fil={(filtarray, in_index, col_id) =>
                  this.callfilter(filtarray, in_index, col_id)
                }
                filtarray={this.state.filtarray}
                timeline={this.state.timeline}
                call_tm={(timeline) => this.calltimeline(timeline)}
                loca={this.state.loca}
                isMobile={this.state.isMobile}
                isLocation={true}
              ></NewFilterCompo>
            )}
            {this.state.list.length === 0 && <div>No Record Found</div>}
            {this.state.list?.map((lstobj, lst_i) => (
              <div className="heading_top table_set over" key={lst_i}>
                <table
                  className="table table-bordered table-striped table-hover p-1"
                  style={{ marginBottom: "0px" }}
                >
                  <thead>
                    <tr className="obj_name">
                      {lstobj?.heading.map((obj, obj_i) => (
                        <th
                          key={obj_i}
                          className="vlpointer"
                          /*  onClick={(e) => this.showupdownbtn(obj.name)} */
                        >
                          {/* {obj.label}
                          {this.state.sortColumn.name === obj.name &&
                            this.state.sortColumn.sort === true && (
                              <i className=" icpadding fa fa-arrow-up"></i>
                            )}
                          {this.state.sortColumn.name === obj.name &&
                            this.state.sortColumn.sort === false && (
                              <i className=" icpadding fa fa-arrow-down"></i>
                            )} */}
                          {obj_i === 0 ? (
                            <span>
                              <input
                                type="checkbox"
                                className="checkpadd"
                                checked={obj.ref}
                                onChange={(e) => {
                                  this.selectedAllRows(e.target.checked);
                                }}
                              ></input>
                              {!this.state.isMobile && (
                                <span className={"btnsrcLst vlpointer"}>
                                  <i
                                    className="fa-solid fa-circle-info lstInfo"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                  ></i>
                                </span>
                              )}
                            </span>
                          ) : (
                            <div onClick={(e) => this.showupdownbtn(obj.name)}>
                              {obj.label}
                              {this.state.sortColumn.name === obj.name &&
                                this.state.sortColumn.sort === true && (
                                  <i className=" icpadding fa fa-arrow-up"></i>
                                )}
                              {this.state.sortColumn.name === obj.name &&
                                this.state.sortColumn.sort === false && (
                                  <i className=" icpadding fa fa-arrow-down"></i>
                                )}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {lstobj?.rcd?.map((objj, objj_i) => (
                      <tr className="obj_value" key={objj_i}>
                        {/* {objj?.colr?.map(
                          (objr, objr_i) =>
                            objr.isInPref && (
                              <td key={objr_i}>
                                <span
                                  // key={objr_i}
                                  className={
                                    objr.firstrecord === true
                                      ? "val_pad val_under vlpointer"
                                      : "val_pad"
                                  }
                                  onMouseDown={
                                    objr.firstrecord === true
                                      ? (e) =>
                                          this.callform(
                                            e,
                                            "first",
                                            objr.table,
                                            objr.r_id
                                          )
                                      : (e) => this.callform("second", "", "")
                                  }
                                >
              
                                  {objr.type === "filter" ||
                                    objr.type === "filter_ref" ||
                                    (objr.name === "json" &&
                                      (JSON.stringify(objr.value)?.length > 50
                                        ? JSON.stringify(objr.value).slice(
                                            0,
                                            50
                                          ) + " ..."
                                        : JSON.stringify(objr.value)))}

                                  {objr.type === "group_key_value" &&
                                    JSON.stringify(
                                      objr.value.properties?.length > 50
                                        ? objr.value.properties.slice(0, 50) +
                                            " ..."
                                        : objr.value.properties
                                    )}
                                  {objr.type === "html" && (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          objr.value?.length > 50
                                            ? objr.value.slice(0, 50) + " ..."
                                            : objr.value,
                                      }}
                                    ></span>
                                  )}
                                  {objr.type === "multi_select" &&
                                    (this.getMultiSelectValues(objr.value)
                                      .length > 50
                                      ? this.getMultiSelectValues(
                                          objr.value
                                        ).slice(0, 50) + " ..."
                                      : this.getMultiSelectValues(objr.value))}

                                  {this.checkIfTypeNotExists(objr.type) &&
                                    (objr.value?.length > 50
                                      ? objr.value.slice(0, 50) + " ..."
                                      : objr.value)}
                                </span>
                                {!this.state.isMobile &&
                                  objr.firstrecord === true && (
                                    <span className="btnsrcRLst vlpointer">
                                      <i
                                        className="fa-solid fa-circle-info lstInfo"
                                        aria-hidden="true"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop"
                                        onClick={() => {
                                          this.selectedInfo(objj_i);
                                        }}
                                      ></i>
                                    </span>
                                  )}
                              </td>
                            )
                        )} */}
                        {objj.colr.map(
                          (objr, objr_i) =>
                            objr.isInPref && (
                              <td
                                key={objr_i}
                                className={
                                  objr.firstrecord === true
                                    ? "val_pad val_under"
                                    : this.isColSelected(objj_i, objr_i)
                                    ? "selected val_pad"
                                    : "val_pad"
                                }
                                // className={
                                //   this.state.multipleSelectedRows.includes(objj_i)
                                //     ? "selected obj_value"
                                //     : "obj_value"
                                // }
                                onDoubleClick={() =>
                                  this.handleDoubleClickOpenForUpdate(
                                    objj_i,
                                    objr_i
                                  )
                                }
                                onClick={(e) => {
                                  this.handleRowClick(e, objj_i, objr_i);
                                }}
                                onMouseDown={
                                  // objr.firstrecord === true
                                  //   ? (e) =>
                                  //       this.callform(
                                  //         e,
                                  //         "first",
                                  //         objr.table,
                                  //         objr.r_id
                                  //       )
                                  //   :
                                     (e) => {
                                        this.handleMouseDown(objj_i, objr_i, e);
                                      }
                                }
                                onMouseUp={this.handleMouseUp}
                                onMouseOver={() =>
                                  this.handleMouseOver(objj_i, objr_i)
                                }
                                onContextMenu={(e) => {
                                  e.preventDefault();
                                  this.setListContext(
                                    e.button,
                                    e.nativeEvent.pageX,
                                    e.nativeEvent.pageY,
                                    { rid: objr.r_id, row: objj_i, col: objr_i }
                                  );
                                }}
                              >
                                {objr_i === 0 ? (
                                  <span
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      className="checkpadd"
                                      checked={objr.ref}
                                      onChange={(e) => {
                                        this.selectedRows(
                                          e.target.checked,
                                          objj_i
                                        );
                                      }}
                                    ></input>

                                    {!this.state.isMobile && (
                                      <span className={"btnsrcLst vlpointer"}>
                                        <i
                                          className="fa-solid fa-circle-info lstInfo"
                                          data-bs-toggle="modal"
                                          data-bs-target="#staticBackdrop"
                                          onClick={() => {
                                            this.selectedInfo(objj_i);
                                          }}
                                        ></i>
                                      </span>
                                    )}
                                  </span>
                                ) : (
                                  <span
                                  className={
                                    objr.firstrecord === true
                                      ? "vlpointer"
                                      : ""
                                  }
                                  onMouseDown={
                                    objr.firstrecord === true
                                      ? (e) =>
                                          this.callform(
                                            e,
                                            "first",
                                            objr.table,
                                            objr.r_id
                                          )
                                      : null
                                  }
                                  >
                                    {/* <span 
                                     onMouseDown={
                                      objr.firstrecord === true
                                        ? (e) =>
                                            this.callform(
                                              e,
                                              "first",
                                              objr.table,
                                              objr.r_id
                                            )
                                        : (e) => {
                                            this.handleMouseDown(objj_i, objr_i, e);
                                          }
                                    }
                                    ></span> */}
                                    {(objr.type === "filter" ||
                                      objr.type === "filter_ref" ||
                                      objr.name === "json") &&
                                      // JSON.stringify(
                                      //   objr.value?.length > 50
                                      //     ? objr.value.slice(0, 50) + " ..."
                                      //     : objr.value
                                      // )
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : JSON.stringify(objr.value)?.length >
                                        50 ? (
                                        JSON.stringify(objr.value).slice(
                                          0,
                                          50
                                        ) + " ..."
                                      ) : (
                                        JSON.stringify(objr.value)
                                      ))}
                                    {objr.type === "group_key_value" &&
                                      // JSON.stringify(
                                      //   objr.value.properties?.length > 50
                                      //     ? objr.value.properties.slice(0, 50) +
                                      //         " ..."
                                      //     : objr.value.properties
                                      // )
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : (
                                        JSON.stringify(
                                          objr.value.properties?.length > 50
                                            ? objr.value.properties.slice(
                                                0,
                                                50
                                              ) + " ..."
                                            : objr.value.properties
                                        )
                                      ))}

                                    {objr.type === "html" &&
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              objr.value?.length > 50
                                                ? objr.value.slice(0, 50) +
                                                  " ..."
                                                : objr.value,
                                          }}
                                        ></span>
                                      ))}

                                    {objr.type === "reference" &&
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            objr.uivalid.visible ===
                                              "false" && (
                                              <p className="inVisibleField">
                                                Security prevents writing to
                                                this field
                                              </p>
                                            )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : objr.value?.value?.length > 50 ? (
                                        objr.value?.value.slice(0, 50) + " ..."
                                      ) : (
                                        objr?.value?.value
                                      ))}

                                    {objr.type === "multi_select" &&
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : this.getMultiSelectValues(objr.value)
                                          .length > 50 ? (
                                        this.getMultiSelectValues(
                                          objr.value
                                        ).slice(0, 50) + " ..."
                                      ) : (
                                        this.getMultiSelectValues(objr.value)
                                      ))}

                                    {this.checkIfTypeNotExists(objr.type) &&
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}

                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            objr.uivalid.visible ===
                                              "false" && (
                                              <p className="inVisibleField">
                                                Security prevents writing to
                                                this field
                                              </p>
                                            )}
                                          {this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : objr.value?.length > 50 ? (
                                        objr.value.slice(0, 50) + " ..."
                                      ) : (
                                        objr.value
                                      ))}
                                  </span>
                                )}
                              </td>
                            )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
            {this.state.noRecord === true && (
              <div className="norcd">No Record Found</div>
            )}
          </div>
        )}
        {this.state.isListRcdInfo && (
          <Form_component
            listRcdInfo={this.state.listRcdInfo}
            handleCloseListRcdInfo={this.handleCloseListRcdInfo}
            isListRcdInfo={this.state.isListRcdInfo}
            loca={this.state.loca}
          ></Form_component>
        )}

        <Modal
          dialogClassName="m-md-auto my-modal d-flex justify-content-center align-items-center"
          // "my-modal"
          // "m-auto my-modal-personalized d-flex justify-content-center align-items-center vh-100"
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Personalized List Column</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PreferenceComponent
              pref="user"
              setRef={(val) => this.setRef(val)}
              columnarray={this.state.prefCall}
              tablename={this.state.listName}
              handleClose={() => this.handleCloseRefresh()}
              loca={this.state.loca}
              isMobile={this.props.isMobile}
              handleClose2={() => {
                this.handleClose();
              }}
            ></PreferenceComponent>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>

        <Modal
          size="xl"
          show={this.state.showmodel}
          onHide={this.handleModalList}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="mod-ht p-1">
            <ModelList
              setRef={(val, r_id) => this.setRef(val, r_id)}
              columnid={this.state.columnid}
              tabId={this.state.tabId}
              loca={this.state.loca}
              colBoolean={true}
              ref_filt={this.state.ref_filter}
              isMobile={this.state.isMobile}
              isVar={false}
            ></ModelList>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleModalList}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default WithNavigate(RelationListComponent);
